// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { useEffect, useRef } from "react"
import styled from "styled-components"
import { useChimeContext } from "../context/ChimeContext"
import { useVideoContext } from "../context/VideoContext"
import { MeetingStatusCode } from "../enums/MeetingStatusCode"



interface LocalVideoProps {
    width?: number
    height?: number
}


const LocalVideoRoot = styled.video<{width?: number, height?: number}>`
    width: ${props => props.width ? props.width + 'px' : '100%'};
    height: ${props => props.height ? props.height + 'px' : '68px'};
`

export default function LocalVideo(props: LocalVideoProps) {
    const chime = useChimeContext()
    const videoElement = useRef(null)
    const videoContext = useVideoContext()
    const localTile = videoContext.getLocalTile()

    useEffect(
        () => {
            const handleTimeout = setTimeout(() => {
                if (
                    chime.getMeetingStatus().meetingStatus !== MeetingStatusCode.Succeeded ||
                    localTile === null ||
                    !localTile.tileId ||
                    !videoElement
                ) {
                    return
                }
                videoContext.bindVideoElement(localTile!.tileId!, videoElement.current as unknown as HTMLVideoElement)
            }, 10)
            return () => {
                clearTimeout(handleTimeout)
            }
        },
        // eslint-disable-next-line
        [localTile?.active]
    )
    return <LocalVideoRoot width={props.width} height={props.height} muted ref={videoElement} style={{ display: localTile?.active ? "inline-block" : "none" }} />
}
